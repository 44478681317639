import {
    setViewRange,
    setPickActivityType,
    setApiRequestCache,
    setReducerModuleState,
    prependApiRequestCacheDataList,
    prependApiRequestCachesDataList,
    appendApiRequestCacheDataList,
    appendApiRequestCachesDataList,
    updateApiRequestCacheDataListItem,
    updateApiRequestCachesDataListItem,
    deleteApiRequestCache,
    deleteApiRequestCaches,
    deleteAllApiRequestCache,
} from "./actions";

type ApiCacheState = {
    apiCache : object
}

type ObjectEntry = [ string, any ];
type ObjectEntriesMapper = (value: ObjectEntry, index?: number) => ObjectEntry;

const mapObjectEntries = ( object: object, callback: ObjectEntriesMapper) => {
    return Object.fromEntries(
        Object
            .entries( object )
            .map(callback)
    )
}

// type RequestCacheEntry = [ null|boolean, any ];
type DataListItem = object & { id: number|string, date: string } ;
type RequestCacheEntryDataList = [ null|boolean, DataListItem[] ];
type ModifyRequestDataList = ( request: RequestCacheEntryDataList, item: DataListItem ) => RequestCacheEntryDataList;

type UpdateCacheDataListConfig = {
    request: string,
    item: DataListItem,
    sortBy: false|string
}
type UpdateCachesDataListConfig = {
    requests: string[],
    item: DataListItem,
    sortBy: false|string
}

const prependRequestDataListItem : ModifyRequestDataList = ( request, item ) => [ request[0], [ item, ...request[1] ] ];
const appendRequestDataListItem : ModifyRequestDataList = ( request, item ) => [ request[0], [ ...request[1], item ] ];
const replaceRequestDataListItem : ModifyRequestDataList = ( request, item ) => [ request[0], request[1].map((curItem) => curItem.id === item.id ? item : curItem) ];

const updateApiCacheDataList = (
    apiCache: object,
    request: string,
    modifierMethod: ModifyRequestDataList,
    item: DataListItem,
    sortBy: false|string
) : ApiCacheState => {
    if( !(request in apiCache) ) {
        return {
            apiCache
        }
    }

    const newCache = modifierMethod(apiCache[request], item);

    if( sortBy ) newCache[1].sort((a,b) => a[sortBy] > b[sortBy] ? -1 : 1);

    return {
        apiCache: {
            ...apiCache,
            [request]: newCache
        }
    }
}

const updateApiCachesDataList = (
    apiCache: object,
    requests: string[],
    modifierMethod: ModifyRequestDataList,
    item: DataListItem,
    sortBy: false|string
) : ApiCacheState => {
    return {
        apiCache: mapObjectEntries( apiCache, ([ key, requestCache ]) => {
            if(!requests.includes(key)) return [key, requestCache];

            const newCache = modifierMethod(requestCache, item);
        
            if( sortBy ) newCache[1].sort((a,b) => a[sortBy] > b[sortBy] ? -1 : 1);

            return [ key, newCache ];
        } )
    }
}

const defaultActions = {
    [setViewRange]: ( viewRange: string ) => {
        return {
            viewRange
        }
    },
    [setPickActivityType]: ( pickActivityType: "view" | "create" ) => {
        return {
            pickActivityType
        }
    },
    [setReducerModuleState]: ({ module, state }, { reducerModuleState }) => {
        return {
            reducerModuleState: {
                ...reducerModuleState,
                [module]: state
            }
        }
    },
    [setApiRequestCache]: ({ request, result }, { apiCache }) => {
        return {
            apiCache: {
                ...apiCache,
                [request]: result
            }
        }
    },
    // Prepend to item to cached data list
    [prependApiRequestCacheDataList]: (
        { request, item, sortBy = false } : UpdateCacheDataListConfig,
        { apiCache } : ApiCacheState
    ) : ApiCacheState => updateApiCacheDataList( apiCache, request, prependRequestDataListItem, item, sortBy ),
    // Prepend to item to cached data lists
    [prependApiRequestCachesDataList]: (
        { requests, item, sortBy = false } : UpdateCachesDataListConfig,
        { apiCache } : ApiCacheState
    ) : ApiCacheState => updateApiCachesDataList( apiCache, requests, prependRequestDataListItem, item , sortBy ),
    // Append to item to cached data list
    [appendApiRequestCacheDataList]: (
        { request, item, sortBy = false } : UpdateCacheDataListConfig,
        { apiCache } : ApiCacheState
    ) : ApiCacheState => updateApiCacheDataList( apiCache, request, appendRequestDataListItem, item, sortBy ),
    // Append to item to cached data lists
    [appendApiRequestCachesDataList]: (
        { requests, item, sortBy = false } : UpdateCachesDataListConfig,
        { apiCache } : ApiCacheState
    ) : ApiCacheState => updateApiCachesDataList( apiCache,requests, appendRequestDataListItem, item, sortBy  ),
    // Update/Replace an item in a cached data list
    [updateApiRequestCacheDataListItem]: (
        { request, item, sortBy = false } : UpdateCacheDataListConfig,
        { apiCache } : ApiCacheState
    ) : ApiCacheState => updateApiCacheDataList( apiCache, request, replaceRequestDataListItem, item, sortBy ),
    // Update/Replace an item in a cached data lists
    [updateApiRequestCachesDataListItem]: (
        { requests, item, sortBy = false } : UpdateCachesDataListConfig,
        { apiCache } : ApiCacheState
    ) : ApiCacheState => updateApiCachesDataList( apiCache, requests, replaceRequestDataListItem, item, sortBy ),
    [deleteApiRequestCache]: ({ request }, { apiCache }) => {
        const {
            [request]: toRemove = false,
            ...trimmedApiCache
        } = apiCache;

        return {
            apiCache: trimmedApiCache
        }
    },
    [deleteApiRequestCaches]: ({ requests } : { requests: string[] }, { apiCache } : ApiCacheState ) : ApiCacheState => {
        // const {
        //     [request]: toRemove = false,
        //     ...trimmedApiCache
        // } = apiCache;

        return {
            apiCache: Object.fromEntries(
                Object
                    .entries( apiCache )
                    .filter(([ key ]) => !requests.includes(key) )
            )
        }
    },
    [deleteAllApiRequestCache]: () => {
        return {
            apiCache: {}
        }
    }
}

export const actions = new Map(Object.entries( defaultActions ));

export const moduleState = {};

export const reducerModules = {
    // tools: () => import("./modules/SampleAsyncStore")
};